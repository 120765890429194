import * as React from "react"
import Layout from '../../components/layout/Layout';
import PageNavigator from "../../components/layout/PageNavigator";
import ContentDossier from '../../components/ContentDossiers';
import dossier from '../../data/certificati-professionali/certificati-ka-kb/dossier.json';
import Seo from "../../components/layout/SEO";

const CertificatiKAKB = () => {
    return (
        <Layout>
            <Seo
                title={"Certificati KA e KB | Autoscuola Faro, Pisa"}
                description={"Ottieni i certificati di Abilitazione Professionale KA e KB presso Autoscuola Faro in centro a Pisa."}
                keywords={[
                    "Certificato KA Pisa",
                    "Certificato KB Pisa",
                    "Abilitazione Professionale Pisa",
                    "Certificati Autoscuola Faro Pisa",
                    "Corsi Certificato KA KB"
                ]}
            />

            <PageNavigator link="/certificati-professionali" text="Certificati professionali" />
            <ContentDossier dossier={dossier} />
        </Layout>
    )
};

export default CertificatiKAKB;